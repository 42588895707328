import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_LARGE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    console.log('error Promise',error);
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  async function(response) {
    return response;
  },
  function(error) {
    // console.log('error',error);
    // // const { config } = error;
    // console.log('Response Error: ' + error.message);

    // 진짜 에러
    // alert(error.message);

    return Promise.reject(error);
  },
);

export function GET_REQUEST(path) {
  return new Promise((success, failed) => {
    http.get(path).then((response) => {
      if (response.status === 200) {
        // const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message

        // if (errCode !== '200'){
        //   let result = {
        //     errCode : errCode,
        //     msg : errMsg
        //   }
        //   failed(result)

        // }else{

        //   let result = {
        //     data : data.body
        //   }
        //   success(result)
        //   console.log(result)
        // }
        success(response)
      }

    }).catch((err) => {
      // console.log(err.toString())
      let result = {
        errCode: err.response.status,
        msg: err.toString()
      }
      failed(result)

    })
  })
}

export function PUT_REQUEST(path, data) {
  return new Promise((success, failed) => {
    http.put(path, data).then((response) => {
      if (response.status === 200) {
        const data = response.data
        const errCode = data.header.result.code
        const errMsg = data.header.notice.message
        const description = data.header.result.description

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg + '\n' + description
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          console.log(result)
        }
      }

    }).catch((err) => {
      console.log(err.toString())
      let result = {
        errCode: err.response.status,
        msg: err.toString()
      }
      failed(result)

    })
  })
}

export function POST_REQUEST(path, data) {
  return new Promise((success, failed) => {
    http.post(path, data).then((response) => {
      if (response.status === 200) {
        // const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message

        // if (errCode !== '200'){
        //   let result = {
        //     errCode : errCode,
        //     msg : errMsg
        //   }
        //   failed(result)

        // }else{

        //   let result = {
        //     data : data.body
        //   }
        //   success(result)
        //   console.log(result)
        // }
        success(response)
      }

    }).catch((err) => {
      // console.log(err.toString())
      let result = {
        errCode: err.response.status,
        msg: err.toString()
      }
      failed(result)

    })
  })
}

export function DELETE_REQUEST(path) {
  return new Promise((success, failed) => {
    http.delete(path).then((response) => {
      if (response.status === 200) {
        const data = response.data
        const errCode = data.header.result.code
        const errMsg = data.header.notice.message

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          console.log(result)
        }
      }

    }).catch((err) => {
      console.log(err.toString())
      let result = {
        errCode: err.response.status,
        msg: err.toString()
      }
      failed(result)

    })
  })
}

export default http;
